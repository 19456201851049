<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
	<div style="height: 100%">
		<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
			<a-col :span="24" class="mb-24">
				<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ height: '100%' }">
					<a-col :span="6" style="height: 100%">
						<div class="dialog_box">
							<div class="list">
								<!-- 原始版本分类 -->
								<a-collapse v-model="roleActiveKey">
									<a-collapse-panel :header="item.title" v-for="item in roleData" :key="item.id">
										<div
											class="item"
											:id="'role_item_' + list.id"
											:class="{ active: list.id == roleActiveId }"
											v-for="list in item.list"
											:key="'role_' + list.id"
											@click="roleItem(list.id)">
											<!-- <div class="line1 c-line-1">
												<a-avatar :src="list.image" /> {{ list.title }}
											</div>
											<div class="line2 c-line-2">{{ list.desc }}</div> -->
											<div style="display: flex; align-items: center">
												<div
													style="
														width: 65px;
														height: 65px;
														margin-right: 20px;
														margin-top: 3px;
														position: relative;
														border-radius: 14px;
													">
													<!-- <a-avatar shape="square" :size="60" :src="list.image" /> -->
													<img width="65" height="65" style="border-radius: 14px" :src="list.image" alt="" />
													<div
														v-show="list.reseller"
														style="
															width: 20px;
															height: 20px;
															position: absolute;
															top: 45px;
															right: 0;
															background-color: #1890ff;
															text-align: center;
															line-height: 20px;
															border-radius: 15px 0px 13px 0px;
														">
														<img
															style="width: 16px; height: 16px; margin-left: 3px; border-radius: 10px"
															src="../assets/imgs/renzheng2.png"
															alt="" />
													</div>
												</div>
												<div class="line1 c-line-1">
													{{ list.title }}
													<img
														style="margin-bottom: 3px"
														v-if="list.sex == '女'"
														width="15"
														height="15"
														src="../assets/imgs/woman.png"
														alt="" />
													<img
														style="margin-bottom: 3px"
														v-else
														width="15"
														height="15"
														src="../assets/imgs/man.png"
														alt="" />
													<div
														class="line2 c-line-2"
														style="
															font-weight: normal;
															display: -webkit-box;
															-webkit-box-orient: vertical;
															-webkit-line-clamp: 1;
															overflow: hidden;
															text-overflow: ellipsis;
														">
														{{ list.welcome }}
													</div>
												</div>
												<!-- <div style="position: absolute; right: 6px; top: 2px; font-weight: normal">
													<img
														width="20"
														height="20"
														src="../assets/imgs/pengyouquan.png"
														@click="jumpFriends(list.id)"
														alt="" />
												</div> -->
												<div style="position: absolute; right: 6px; bottom: 4px; font-weight: normal">
													<img width="12" height="12" src="../assets/imgs/对话svg图标.svg" alt="" />{{ list.usages }}
												</div>
											</div>
										</div>
									</a-collapse-panel>
								</a-collapse>

								<!--  -->
								<!-- <a-collapse v-model="roleActiveKey"> -->
								<!-- 版本2聊过天 -->
								<!-- <div v-for="item in roleData" :key="item.id">
									<div class="item" :id="'role_item_' + list.id"
										:class="{ active: list.id == roleActiveId }" v-for="list in item.list"
										:key="'role_' + list.id" @click="roleItem(list.id)">
										<div style="display: flex;">
											<div
												style="width: 65px;height: 65px;margin-right: 20px;margin-top: 3px;position: relative;">
												<a-avatar shape="square" :size="60" :src="list.image" />
												<img width="65" height="65" style="border-radius: 14px;" :src="list.image"
													alt="">
												<div v-show="list.reseller"
													style="width: 20px; height: 20px;position: absolute;top: 45px;right: 0 ;background-color: #1890ff;text-align: center; line-height: 20px; border-radius: 15px 0px 13px 0px;">
													<img style="width: 16px;height: 16px;margin-left: 3px;;border-radius: 10px;"
														src="../assets/imgs/renzheng2.png" alt="">
												</div>
											</div>
											<div class="line1 c-line-1">
												{{ list.title }}<img style="margin-bottom: 3px;margin-left: 2px;"
													v-if="list.sex == '女'" width="15" height="15"
													src="../assets/imgs/woman.png" alt="">
												<img style="margin-bottom: 4px;margin-left: 2px;" v-else width="15"
													height="15" src="../assets/imgs/man.png" alt="">
												<div class="line2 c-line-2" style="font-weight: normal;">{{ list.desc }}
												</div>
											</div>
											<div style="position: absolute;right: 6px;bottom: 2px;font-weight: normal;">
												<img width="12" height="12" src="../assets/imgs/对话svg图标.svg" alt=""> {{
													list.usages
												}}
											</div>
										</div>
									</div>
								</div> -->
								<!-- </a-collapse> -->

								<!-- 版本3 选中的 -->
							</div>
						</div>
					</a-col>
					<a-col :span="18" style="height: 100%">
						<div class="content_box">
							<!-- 虚拟提示 -->
							<!-- <a-alert
								style="width: 40%;margin: 0 auto;text-align: center;margin-top: -10px;margin-bottom: 5px;"
								v-if="visible" message="注意：数字人的对话内容均由人工智能技术生成" type="warning" closable
								:after-close="handleClose" /> -->
							<div
								style="
									width: 100%;
									height: 50px;
									font-size: 12px;
									color: #dbd5d5;
									background: #f7f7f7;
									text-align: center;
									line-height: 50px;
								">
								注意：数字人的对话内容均由人工智能技术生成
							</div>
							<div class="output" id="output">
								<div
									class="list_box"
									:class="{ active: dItem.id == roleActiveId }"
									v-if="dItem.id == roleActiveId"
									v-for="dItem in roleDataItem()"
									:key="'rlist_' + dItem.id">
									<!-- roleData  dItem.list-->
									<div class="list" v-for="(item, index) in dItem.list" :key="'msg_' + item.id">
										<!-- 如果是文字，就是v-md-editor -->
										<div class="item right" v-if="item.message" v-show="true">
											<div class="message">
												<v-md-editor v-model="item.message" mode="preview"></v-md-editor>
											</div>
											<div class="avatar">
												<img :src="userInfo.avatar ? userInfo.avatar : defalultImg" alt="" />
											</div>
										</div>
										<div class="item right" v-if="item.message" v-show="false">
											<div class="message">
												<audio src="" controls autoplay></audio>
											</div>
											<div class="avatar">
												<img :src="userInfo.avatar ? userInfo.avatar : defalultImg" alt="" />
											</div>
										</div>

										<div class="item left" v-if="item.response" v-show="true">
											<div class="avatar">
												<img :src="dItem.image" alt="" />
											</div>
											<!-- <div>
												{{ item.response }}
											</div> -->
											<div class="message">
												<v-md-editor v-model="item.response" mode="preview"></v-md-editor>
												<!-- <div class="tools" v-if="!item.transmit">
													<div class="copy" @click="messageCopy(item.response)">
														<a-icon type="copy" />
														<span>复制</span>
													</div>
													<div class="collect" @click="messageCollect(item.id)">
														<a-icon type="heart" />
														<span>收藏</span>
													</div>
												</div> -->
											</div>
										</div>
										<div class="item left" v-if="item.response" v-show="false">
											<div class="avatar">
												<img :src="dItem.image" alt="" />
											</div>
											<div class="message">
												<audio src="" controls autoplay></audio>
												<div class="tools" v-if="!item.transmit">
													<!-- <div class="copy" @click="messageCopy(item.response)">
														<a-icon type="copy" />
														<span>复制</span>
													</div> -->
													<!-- 放个时间吧 -->
													<!-- <div class="collect" @click="messageCollect(item.id)">
														<a-icon type="heart" />
														<span>收藏</span>
													</div> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 表情 -->
							<div class="emoji-panel" v-if="emojiShow">
								<div class="icon" @click="handleShow"><a-icon type="close-circle" /></div>
								<span
									class="em"
									:class="emoji"
									@click.stop="selectEmoji(emoji)"
									v-for="(emoji, index) in emojiNewList"
									:key="index"
									>{{ emoji.emote_img }}</span
								>
							</div>
							<div class="input">
								<textarea
									class="textarea"
									v-model="inputText"
									@keyup.enter="handleKeyCode"
									placeholder="请输入内容（Enter 发送 / Shift + Enter 换行）"></textarea>
								<div class="button">
									<!-- <a-icon style="margin-right: 10px;" type="audio" theme="twoTone"
										@click="handleBtnClick" />
									<a-icon style="margin-right: 10px;" type="audio" @click="cancel_mp3" />
									<a-icon style="margin-right: 10px;" type="play-circle" @click="play_mp3" /> -->

									<!-- 版本1 js-audio-recorder-->
									<!-- <a-button type="primary" size="small" @click="sendRecording"> 录音 </a-button>
									<a-button type="primary" size="small" @click="close"> 结束 </a-button>
									<a-button type="primary" size="small" @click="play"> 播放 </a-button> -->

									<!-- 版本2 recorder-core-->
									<!-- <button @click="recOpen">打开录音,请求权限</button> -->
									<!-- <button @click="recStart">开始录音</button>
									<button @click="recStop">结束录音</button>
									<button @click="recPlay">本地试听</button> -->

									<!-- 版本3 /utils/recorder-->
									<!-- <a-button @click="myrecording" style="margin-left: 1rem">{{ time }}</a-button>
									<a-button @click="startPlay" style="margin-left: 1rem">{{ playing ? "播放" : "暂停" }}</a-button>
									<a-button @click="delvioce" style="margin-left: 1rem">删除</a-button>
									<audio :src="fileurl" style="display: none" ref="audio" autoplay id="myaudio"></audio> -->

									<a-button type="primary" size="small" :disabled="!disabled" @click="sendMessage"> 发送 </a-button>
									<a-button type="primary" size="small" @click="handleShow" style="margin-left: 5px"> 表情 </a-button>
								</div>
							</div>
						</div>
					</a-col>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	//
	import RecorderAudio from "js-audio-recorder";
	// import Recorderx, { ENCODE_TYPE } from "recorderx";
	//必须引入的核心
	import Recorder from "recorder-core";
	//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
	import "recorder-core/src/engine/mp3";
	import "recorder-core/src/engine/mp3-engine";
	//录制wav格式的用这一句就行
	import "recorder-core/src/engine/wav";
	//可选的插件支持项，这个是波形可视化插件
	import "recorder-core/src/extensions/waveview";
	//ts import 提示：npm包内已自带了.d.ts声明文件（不过是any类型）

	//版本3
	import recording from "@/utils/recorder";
	export default {
		components: {},
		data() {
			return {
				inputText: "", // 发送数据
				disabled: false, // 按钮状态
				dialogList: [], // 会话列表
				dialogId: 0, // 当前会话id
				createModal: false, // 创建会话标题弹窗
				createLoading: false, // 异步加载状态
				createTitle: "", // 新建会话标题
				modalType: "create", // create / edit
				editId: "", // 编辑标题ID
				roleData: [],
				roleActiveKey: [],
				roleActiveId: 0,
				roleActiveId2: 0,
				roleFirstId: "",
				visible: true,
				// 表情包
				emojiShow: false,
				emojiNewList: [],
				defalultImg: require("@/assets/imgs/morentou.png"),
				/********************录音****************/
				// 版本1
				recorder: null,

				// 版本2
				rec: null,
				recBlob: null,
				realTimeSendTryType: null,
				realTimeSendTryEncBusy: null,
				realTimeSendTryTime: 0,
				realTimeSendTryNumber: null,
				transferUploadNumberMax: null,
				realTimeSendTryChunk: null,
				testOutputWavLog: false, //本测试如果不是输出wav格式，就顺带打一份wav的log，录音后执行mp3、wav合并的demo代码可对比音质
				testSampleRate: 16000,
				testBitRate: 16,
				SendInterval: 300,

				// 版本3
				RecordingSwitch: true, //录音开关
				files: "", //语音文件
				num: 60, // 按住说话时间
				fileurl: "", //语音URL
				interval: "", //定时器
				time: "点击说话(60秒)",
				playing: true,

				// WebSocket
				socket: "",
				wssurlAsr: null, //获取腾讯云链接
				sendSoket: {},
			};
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
			...mapGetters("app", ["ws_url"]),
		},
		watch: {
			inputText(newValue, oldValue) {
				this.disabled = newValue == "" ? false : true;
			},
		},
		created() {
			this.roleActiveId2 = this.$route.query.id;
			this.getEmote();
			this.getRoleList();
			// this.getRoleHistory()
			// this.createRoleChat()
			// this.recorder = new Recorder();
			if (this.token) {
				this.getWssurlAsr(); //获取腾讯云链接
			}
		},
		mounted() {},
		methods: {
			jumpFriends(id) {
				// jumpFriends
				this.$router.push("/friends?rid=" + id);
			},
			// 版本1---js-audio-recorder
			sendRecording() {
				this.recorder = new RecorderAudio();
				RecorderAudio.getPermission().then(
					() => {
						console.log("开始录音");
						this.recorder.start(); // 开始录音
						this.init();
					},
					(error) => {
						this.$message({
							message: "请先允许该网页使用麦克风",
							type: "info",
						});
						console.log(`${error.name} : ${error.message}`);
					},
				);
			},
			close() {
				console.log("停止录音");
				this.recorder.stop(); // 停止录音
			},
			play() {
				console.log("播放录音");
				this.recorder.play(); // 播放录音
				const blob = this.recorder.getWAVBlob(); //获取录音插件返回的blob数据
				console.log("获取录音插件返回的blob数据", blob);
				this.recorder.getPCMBlob(); //获取录音插件返回的blob数据

				const reader = new FileReader(); //借助FileReader对象
				reader.onload = function () {
					this.sendSoket = this.result;
					console.log(this.result);
					console.log("自定义的数据", this.sendSoket);
				};
				let aaa = reader.readAsArrayBuffer(blob); //这里将blob转化成了ArrayBuffer类型

				console.log("实例化FileReader", aaa);
				this.send(this.sendSoket);
			},

			// 版本2---recorder-core
			//重置环境，每次开始录音时必须先调用此方法，清理环境
			RealTimeSendTryReset(type) {
				this.realTimeSendTryType = type;
				this.realTimeSendTryTime = 0;
			},
			// =====实时处理核心函数==========
			RealTimeSendTry(buffers, bufferSampleRate, isClose) {
				var t1 = Date.now();
				if (this.realTimeSendTryTime == 0) {
					this.realTimeSendTryTime = t1;
					this.realTimeSendTryEncBusy = 0;
					this.realTimeSendTryNumber = 0;
					this.transferUploadNumberMax = 0;
					this.realTimeSendTryChunk = null;
				}
				if (!isClose && t1 - this.realTimeSendTryTime < this.SendInterval) {
					return; //控制缓冲达到指定间隔才进行传输
				}
				this.realTimeSendTryTime = t1;
				var number = ++this.realTimeSendTryNumber;

				var pcm = [],
					pcmSampleRate = 0;
				if (buffers.length > 0) {
					//借用SampleData函数进行数据的连续处理，采样率转换是顺带的，得到新的pcm数据
					var chunk = Recorder.SampleData(buffers, bufferSampleRate, testSampleRate, realTimeSendTryChunk, {
						frameType: isClose ? "" : realTimeSendTryType,
					});

					//清理已处理完的缓冲数据，释放内存以支持长时间录音，最后完成录音时不能调用stop，因为数据已经被清掉了
					for (var i = this.realTimeSendTryChunk ? this.realTimeSendTryChunk.index : 0; i < chunk.index; i++) {
						buffers[i] = null;
					}
					this.realTimeSendTryChunk = chunk; //此时的chunk.data就是原始的音频16位pcm数据（小端LE），直接保存即为16位pcm文件、加个wav头即为wav文件、丢给mp3编码器转一下码即为mp3文件

					pcm = chunk.data;
					pcmSampleRate = chunk.sampleRate;
				}

				//没有新数据，或结束时的数据量太小，不能进行mock转码
				if (pcm.length == 0 || (isClose && pcm.length < 2000)) {
					this.TransferUpload(number, null, 0, null, isClose);
					return;
				}

				//实时编码队列阻塞处理
				if (!isClose) {
					if (this.realTimeSendTryEncBusy >= 2) {
						Runtime.Log("编码队列阻塞，已丢弃一帧", 1);
						return;
					}
				}
				this.realTimeSendTryEncBusy++;

				//通过mock方法实时转码成mp3、wav；16位pcm格式可以不经过此操作，直接发送new Blob([pcm.buffer],{type:"audio/pcm"}) 要8位的就必须转码
				var encStartTime = Date.now();
				var recMock = Recorder({
					type: this.realTimeSendTryType,
					sampleRate: this.testSampleRate, //采样率
					bitRate: this.testBitRate, //比特率
				});
				recMock.mock(pcm, pcmSampleRate);
				recMock.stop(
					function (blob, duration) {
						this.realTimeSendTryEncBusy && this.realTimeSendTryEncBusy--;
						blob.encTime = Date.now() - encStartTime;

						//转码好就推入传输
						this.TransferUpload(number, blob, duration, recMock, isClose);
					},
					function (msg) {
						this.realTimeSendTryEncBusy && this.realTimeSendTryEncBusy--;
						//转码错误？没想到什么时候会产生错误！
						Runtime.Log("不应该出现的错误:" + msg, 1);
					},
				);

				if (this.testOutputWavLog && this.realTimeSendTryType != "wav") {
					//测试输出一份wav，方便对比数据
					var recMock2 = Recorder({
						type: "wav",
						sampleRate: this.testSampleRate,
						bitRate: 16,
					});
					recMock2.mock(pcm, pcmSampleRate);
					recMock2.stop(function (blob, duration) {
						var logMsg = "No." + (number < 100 ? ("000" + number).substr(-3) : number);
						Runtime.LogAudio(blob, duration, recMock2, logMsg);
					});
				}
			},

			//=====数据传输函数==========
			TransferUpload(number, blobOrNull, duration, blobRec, isClose) {
				transferUploadNumberMax = Math.max(transferUploadNumberMax, number);
				if (blobOrNull) {
					var blob = blobOrNull;
					var encTime = blob.encTime;

					//*********发送方式一：Base64文本发送***************
					var reader = new FileReader();
					reader.onloadend = function () {
						var base64 = (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result) || [])[1];

						//可以实现
						//WebSocket send(base64) ...
						//WebRTC send(base64) ...
						//XMLHttpRequest send(base64) ...

						//这里啥也不干
					};
					reader.readAsDataURL(blob);

					//*********发送方式二：Blob二进制发送***************
					//可以实现
					//WebSocket send(blob) ...
					//WebRTC send(blob) ...
					//XMLHttpRequest send(blob) ...

					//****这里仅 console.log一下 意思意思****
					var numberFail =
						number < transferUploadNumberMax
							? '<span style="color:red">顺序错乱的数据，如果要求不高可以直接丢弃，或者调大SendInterval试试</span>'
							: "";
					var logMsg = "No." + (number < 100 ? ("000" + number).substr(-3) : number) + numberFail;

					Runtime.LogAudio(blob, duration, blobRec, logMsg + "花" + ("___" + encTime).substr(-3) + "ms");

					if (true && number % 100 == 0) {
						//emmm....
						Runtime.LogClear();
					}
				}

				if (isClose) {
					Runtime.Log("No." + (number < 100 ? ("000" + number).substr(-3) : number) + ":已停止传输");
				}
			},

			//

			recOpen() {
				//创建录音对象
				this.rec = Recorder({
					type: "mp3", //录音格式，可以换成wav等其他格式
					sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
					bitRate: 16, //录音的比特率，越大音质越好
					onProcess: (buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) => {
						//录音实时回调，大约1秒调用12次本回调
						//可实时绘制波形，实时上传（发送）数据
						// if (this.wave) this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
						Runtime.Process.apply(null, arguments);

						//推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的（此时采样率为浏览器采集音频的原始采样率），只需清理buffers就能释放内存，其他格式不一定有此特性。
						RealTimeSendTry(buffers, bufferSampleRate, false);
					},
				});

				//打开录音，获得权限
				this.rec.open(
					() => {
						console.log("录音已打开");
						// if (this.$refs.recwave) {
						//创建音频可视化图形绘制对象
						// this.wave = Recorder.WaveView({ elem: this.$refs.recwave });
						// }
					},
					(msg, isUserNotAllow) => {
						//用户拒绝了录音权限，或者浏览器不支持录音
						console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg);
					},
				);
			},

			recStart() {
				// if (!this.rec) {
				// 	console.error("未打开录音");
				// 	return;
				// }
				// this.rec.start();
				// console.log("已开始录音");

				if (this.rec) {
					this.rec.close();
				}
				this.rec = Recorder({
					type: "mp3", //录音格式，可以换成wav等其他格式
					sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
					bitRate: 16, //录音的比特率，越大音质越好
					onProcess: function (buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) {
						//录音实时回调，大约1秒调用12次本回调
						//可实时绘制波形，实时上传（发送）数据
						// if (this.wave) this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
						Runtime.Process.apply(null, arguments);
						//推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的（此时采样率为浏览器采集音频的原始采样率），只需清理buffers就能释放内存，其他格式不一定有此特性。
						RealTimeSendTry(buffers, bufferSampleRate, false);
					},
				});

				// var t = setTimeout(function () {
				// 	this.$message.err("无法录音：权限请求被忽略（超时假装手动点击了确认对话框）");
				// }, 8000);
				this.rec.open(
					function () {
						//打开麦克风授权获得相关资源
						// clearTimeout(t);
						this.rec.start(); //开始录音【未定义】
						this.RealTimeSendTryReset(type); //重置环境，开始录音时必须调用一次
					},
					function (msg, isUserNotAllow) {
						// clearTimeout(t);
						Runtime.Log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg, 1);
					},
				);
			},
			recStop() {
				if (!this.rec) {
					console.error("未打开录音");
					return;
				}
				this.rec.stop(
					(blob, duration) => {
						//blob就是我们要的录音文件对象，可以上传，或者本地播放
						this.recBlob = blob;
						//简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
						var localUrl = (window.URL || webkitURL).createObjectURL(blob);
						console.log("录音成功", blob, localUrl, "时长:" + duration + "ms");
						// this.upload(blob); //把blob文件上传到服务器
						this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
						this.rec = null;

						this.RealTimeSendTry([], 0, true); //最后一次发送
						// {"type": "end"}
						// this.socket.send({
						// 	data: JSON.stringify({
						// 		type: "end",
						// 	}),
						// });
					},
					(err) => {
						console.error("结束录音出错：" + err);
						this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
						this.rec = null;
					},
				);
			},
			recPlay() {
				//本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
				var localUrl = URL.createObjectURL(this.recBlob);
				var audio = document.createElement("audio");
				audio.controls = true;
				document.body.appendChild(audio);
				audio.src = localUrl;
				audio.play(); //这样就能播放了

				//注意不用了时需要revokeObjectURL，否则霸占内存
				setTimeout(function () {
					URL.revokeObjectURL(audio.src);
				}, 5000);
			},

			// 版本3---utils/recorder
			myrecording() {
				if (this.files === "") {
					if (this.RecordingSwitch) {
						this.Start();
					} else {
						this.End();
					}
				} else if (this.time === "点击重录(60秒)") {
					this.files = "";
					this.Start();
				}
				this.RecordingSwitch = !this.RecordingSwitch;
			},
			// 点击播放
			startPlay() {
				console.log("音频流", this.fileurl);
				if (this.playing) {
					this.$refs.audio.play();
				} else {
					this.$refs.audio.pause();
				}
				this.playing = !this.playing;
			},
			// 删除语音
			delvioce() {
				// this.fileurl = "";
				// this.files = "";
				// this.num = 60;
				// this.time = "点击说话(60秒)";
				this.send("发送消息试试");
			},
			// 清除定时器
			clearTimer() {
				if (this.interval) {
					this.num = 60;
					clearInterval(this.interval);
				}
			},
			// 开始录制
			Start() {
				this.clearTimer();
				recording.get((rec) => {
					// 当首次按下时，要获取浏览器的麦克风权限，所以这时要做一个判断处理
					if (rec) {
						this.recorder = rec;
						this.interval = setInterval(() => {
							if (this.num <= 0) {
								this.recorder.stop();
								this.num = 60;
								this.End();
							} else {
								this.time = "点击结束（" + this.num + "秒）";
								this.recorder.start();
								this.num--;
							}
						}, 1000);
						this.init();
					}
				});
			},
			// 停止录制
			End() {
				this.clearTimer();
				if (this.recorder) {
					this.recorder.stop(); // 重置说话时间
					this.num = 60;
					this.time = "点击重录(60秒)"; // 获取语音二进制文件
					let bold = this.recorder.getBlob(); // 将获取的二进制对象转为二进制文件流
					console.log("点击说话(60秒)获取到的数据", bold);
					/** files对象可以发送成功，但是返回没得文字**/
					let files = new File([bold], "test.mp3", {
						type: "audio/mp3",
						lastModified: Date.now(),
					});
					this.files = files;
					console.log("将获取的二进制对象转为二进制文件流", files);
					/**试试ArrayBuffer对象，错误：客户端上传未知文本消息**/
					// const reader = new FileReader(); //借助FileReader对象
					// reader.onload = function () {
					// 	this.sendSoket = this.result;
					// 	console.log(this.result);
					// 	console.log("自定义的数据", this.sendSoket);
					// };
					// let aaa = reader.readAsArrayBuffer(bold); //这里将blob转化成了ArrayBuffer类型
					// console.log("实例化FileReader", aaa);
					this.send(this.files);
					//获取音频时长
					// let fileurl = URL.createObjectURL(files);
					// this.fileurl = fileurl;
					// let audioElement = new Audio(fileurl);
					// let duration;
					// audioElement.addEventListener("loadedmetadata", function (_event) {
					// 	duration = audioElement.duration;
					// 	console.log("视频时长:" + duration);
					// });
				}
			},

			// WebSocket
			init: function () {
				if (typeof WebSocket === "undefined") {
					alert("您的浏览器不支持socket");
				} else {
					// 实例化socket
					this.socket = new WebSocket(this.wssurlAsr);
					// 监听socket连接
					this.socket.onopen = this.open;
					// 监听socket错误信息
					this.socket.onerror = this.error;
					// 监听socket消息
					this.socket.onmessage = this.getMessage;
				}
			},
			open: function (res) {
				console.log("判断是否掉线", res);
				// 1连接已建立，可以进行通信 2连接正在进行关闭 3连接已经关闭或者连接不能打开
				console.log("判断是否掉线22222", this.socket.readyState);
				console.log("socket连接成功");
			},
			error: function () {
				console.log("连接错误");
				// 重新链接
			},
			getMessage: function (msg) {
				let data = JSON.parse(msg.data);
				if (data.code == 4008) {
					// 客户端超过15秒未发送音频数据---重新录制
				}
				if (res.data.code === 0 && res.data.result) {
					console.log("识别成功", res.data.result);
				} else if (res.data.code == 0) {
					// this.startRecorder();
				} else {
					console.log("识别失败", res.data);
				}
				console.log(data, 123);
			},
			// 发送消息给被连接的服务端
			send: function (params) {
				this.socket.send(params);
			},
			close: function () {
				console.log("socket已经关闭");
			},

			// 111111111111111111111111111111111111111111111111111111111111111111111111111111111111
			// 合并角色数据
			roleDataItem() {
				let arr = [];
				this.roleData.map((item) => {
					item.list.map((list) => {
						arr.push(list);
					});
				});
				console.log("roleDataItem()  return arr;", arr);
				return arr;
			},
			// 切换角色类型
			roleItem(id) {
				this.roleActiveId = id;
				this.roleData.map((item) => {
					item.list.map((list) => {
						if (list.id == id) {
							if (!list.transmit && !list.list.length) this.getRoleHistory();
						}
					});
				});
			},
			// 获取角色列表
			getRoleList() {
				// 版本1，有分类role.second
				// 版本2，聊过天的user.ruleMsg
				// 版本3，选中的角色
				this.$http("role.second", { id: this.roleActiveId2 }).then((res) => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							let obj = {};
							res.data.forEach((item) => {
								const key = item.topic_id;
								const value = {
									list: [],
									transmit: false,
									...item,
								};
								if (!obj[key]) {
									obj[key] = [value];
								} else {
									obj[key].push(value);
								}

								if (item.id == this.$route.query.id) {
									this.roleFirstId = item.topic_id;
								}
							});

							let arr = [];
							Object.keys(obj).forEach((key) => {
								arr.push({
									id: key,
									title: obj[key][0].topic_name,
									list: obj[key],
								});
								this.roleActiveKey.push(key);
							});
							if (this.$route.query.id) {
								this.roleActiveId = this.$route.query.id;
							} else {
								this.roleActiveId = arr[0].list[0].id;
							}
							this.getRoleHistory();
							this.roleData = arr;
							this.$nextTick(() => {
								document
									.getElementById(`role_item_` + this.roleActiveId)
									.scrollIntoView({ behavior: "smooth", block: "center" });
							});
						}
					}
				});
			},
			// 获取腾讯云链接getWssurlAsr
			getWssurlAsr() {
				this.$http("chat.getWssurlAsr").then((res) => {
					if (res.code === 1) {
						//   console.log(res.data);
						this.wssurlAsr = res.data;
						//   this.useWebSocket(res.data);
					}
				});
			},

			// 历史记录
			getRoleHistory() {
				this.$http("role.history", { prompt_id: this.roleActiveId }).then((res) => {
					if (res.code === 1) {
						this.roleData.map((item) => {
							item.list.map((list) => {
								if (list.id == this.roleActiveId) {
									list.list = res.data.reverse();
								}
							});
						});
						this.createRoleChat();
					}
				});
			},
			// 创建角色会话
			createRoleChat() {
				this.$http("role.create", { prompt_id: this.roleActiveId }).then((res) => {
					if (res.code === 1) {
						this.roleData.map((item) => {
							item.list.map((list) => {
								if (list.id == this.roleActiveId) {
									list.group_id = res.data.group_id;
									if (!list.list.length) {
										list.list.unshift({
											id: "welcome",
											message: "",
											response: res.data.prompt.welcome,
											transmit: false,
										});
									}
								}
							});
						});
						// this.roleData=res.data.prompt
					}
				});
			},
			// 信息复制
			async messageCopy(text) {
				try {
					await navigator.clipboard.writeText(text);
					this.$message.success("已复制到剪切板");
				} catch (err) {
					this.$message.error("复制失败");
				}
			},
			// 信息收藏
			messageCollect(id) {
				console.log(id, "收藏ID");
			},

			// 发送内容
			sendMessage() {
				this.emojiShow = false;
				this.roleData.map((item) => {
					item.list.map((list) => {
						if (list.id == this.roleActiveId) {
							if (list.transmit) {
								this.$message.warning("请等待当前对话完成...");
							} else {
								if (!this.inputText) {
									console.log("输入为空");
									return;
								}
								this.fetchDataStream(this.inputText, this.roleActiveId, list.group_id);
							}
						}
					});
				});
			},
			// 发送请求
			async fetchDataStream(message, dId, gId) {
				if (!message) {
					console.log("输入为空");
					return;
				}

				this.inputText = "";
				this.roleData.map((item) => {
					item.list.map((list) => {
						if (list.id == dId) {
							list.list.unshift({
								id: "message",
								message,
								response: "",
								transmit: true,
							});
						}
					});
				});

				this.disabled = true;
				const postData = { type: "rule", message, group_id: gId, prompt_id: dId },
					url = this.$BASE_API + "/addons/chatgpt/web/sendText",
					controller = new AbortController(),
					Token = this.token,
					Sign = window.location.search.replace(/\?/g, "");

				try {
					const response = await fetch(url, {
						method: "post",
						headers: {
							"Content-Type": "application/json;charset=utf-8",
							Token,
							Sign,
						},
						body: JSON.stringify(postData),
						signal: controller.signal,
					});

					const reader = response.body.getReader();
					let data = "";

					while (true) {
						const { done, value } = await reader.read(),
							str = new TextDecoder().decode(value);

						if (str.indexOf("data: [DONE]") != -1 || str.indexOf("data:[DONE]") != -1 || done) {
							const id = str.replaceAll(" ", "").split("data:[DONE]")[1];

							this.roleData.map((item) => {
								item.list.map((list) => {
									if (list.id == dId) {
										list.transmit = false;
										list.list[0].transmit = null;
										list.list[0].id = id;
									}
								});
							});
							break;
						}

						data += str;
						this.roleData.map((item) => {
							item.list.map((list) => {
								if (list.id == dId) {
									list.list[0].response = data;
									console.log("返回值123", data);
								}
							});
						});
					}
				} catch {
					console.error("请求失败");
				}
			},
			//  监听按键 Shift + Enter 换行  请输入内容（Enter发送/Shift+Enter换行）
			handleKeyCode(event) {
				if (event.keyCode == 13) {
					if (!event.shiftKey) {
						this.inputText && this.sendMessage(); // 发消息
					} else {
						event.preventDefault();
						this.inputText && (this.inputText = this.inputText + "\n"); // 换行
					}
				}
			},
			handleClose() {
				this.visible = false;
			},

			// 表情包
			// 聊天表情包
			getEmote() {
				this.$http("user.getEmote").then((res) => {
					if (res.code === 1) {
						this.emojiNewList = res.data;
						// console.log("聊天表情包", this.emojiNewList);
					}
				});
			},
			selectEmoji(data) {
				let val = data.emote_img;
				this.inputText += val;
			},
			handleShow() {
				this.emojiShow = !this.emojiShow;
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .ant-collapse {
		border: none;
		background: transparent;

		.ant-collapse-item {
			border: none;

			.ant-collapse-header {
				padding: 12px 16px;

				.ant-collapse-arrow {
					left: 0;
				}
			}

			.ant-collapse-content {
				border: none;
				background: transparent;

				.ant-collapse-content-box {
					padding: 0;
				}
			}
		}
	}

	.dialog_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background: #f7f7f7;
		padding: 12px 0;

		.list {
			flex: 1;
			height: 100%;
			padding: 12px;
			overflow: hidden;
			padding-top: 0;

			&:hover {
				overflow-y: scroll;
				overflow-x: hidden;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 4px solid rgba(0, 0, 0, 0);
				box-shadow: 4px 0 0 #a5adb7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 4px 0 0 #4a4a4a inset;
			}

			// 滚动条两端按钮
			&::-webkit-scrollbar-button {
				height: 10px;
			}

			.item {
				padding: 12px;
				background: #fff;
				border-radius: 10px;
				margin-bottom: 12px;
				cursor: pointer;
				position: relative;

				&:last-child {
					margin-bottom: 0;
				}

				&.active {
					border: 1px solid #1890ff;
					box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
				}

				.line1 {
					color: #000;
					font-weight: 700;
				}

				.line2 {
					margin-top: 4px;
					color: #999;
				}
			}
		}
	}

	.content_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;

		// 滚动条整体
		::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}

		// 滚动条滑块
		::-webkit-scrollbar-thumb {
			background-clip: padding-box;
			background-color: #a5adb7;
			border: 1px dashed rgba(0, 0, 0, 0);
			border-radius: 6px;
		}

		// 滚动条滑块 hover
		::-webkit-scrollbar-thumb:hover {
			background: #4a4a4a;
		}

		// 滚动条轨道
		::-webkit-scrollbar-track {
			background-color: transparent;
		}

		// 滚动条两端按钮
		::-webkit-scrollbar-button {
			height: 10px;
		}

		.output {
			flex: 1;
			border-radius: 12px;
			background: #f7f7f7;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			// margin-top: 10px;

			.list_box {
				overflow-y: auto;
				overflow-x: hidden;
				display: flex;
				flex-direction: column-reverse;
				position: relative;
				z-index: 1000;
				padding: 20px;
				margin: 0 6px;

				.list {
					margin-bottom: 20px;

					.item {
						margin-bottom: 20px;
						display: flex;
						flex-wrap: nowrap;
						$imgsize: 40px;

						.avatar {
							width: $imgsize;
							height: $imgsize;
							border-radius: 50%;
							overflow: hidden;
							border: 2px solid #fff;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.message {
							max-width: calc(100% - #{$imgsize + 20px} * 2);
							padding: 10px 12px;

							::v-deep .v-md-editor__right-area {
								width: auto;
								min-width: 0;
							}

							::v-deep .vuepress-markdown-body {
								padding: 0;
							}
						}

						&.left {
							justify-content: flex-start;

							.avatar {
								margin-right: 20px;
							}

							.message {
								border-radius: 0 12px 12px 12px;
								background: #fff;
								color: #000;
								position: relative;

								&:hover {
									.tools {
										display: flex;
									}
								}

								.tools {
									position: absolute;
									bottom: 0;
									left: 0;
									transform: translateY(100%);
									padding-top: 10px;
									display: flex;
									align-items: center;
									display: none;

									.copy,
									.collect {
										padding: 4px 10px;
										border: 1px solid #eee;
										border-radius: 6px;
										cursor: pointer;

										&:hover {
											box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
											background: #fff;
										}

										span {
											margin-left: 4px;
										}
									}

									.collect {
										margin-left: 12px;
									}
								}
							}
						}

						&.right {
							justify-content: flex-end;

							.avatar {
								margin-left: 20px;
							}

							.message {
								border-radius: 12px 0 12px 12px;
								// background: $message_color;
								background: #40a9ff;
								color: #fff;

								::v-deep .vuepress-markdown-body {
									// background: $message_color;
									background: #40a9ff;
									color: #fff;
								}
							}
						}
					}
				}
			}
		}

		.input {
			margin-top: 20px;
			height: 120px;
			position: relative;
			background: #f7f7f7;
			border-radius: 12px;
			padding: 12px 84px 12px 12px;

			.textarea {
				width: 100%;
				height: 100%;
				border: none;
				outline: none;
				resize: none;
				background: transparent;
				padding: 0;
				margin: 0;
			}

			.button {
				position: absolute;
				bottom: 12px;
				right: 12px;
			}
		}
	}

	// 表情包
	.emoji-panel {
		position: absolute;
		bottom: 115px;
		right: 12px;
		width: 370px;
		padding-bottom: 10px;
		border: 1px solid #ececec;
		margin-bottom: 5px;
		background-color: #ffffff;
		box-shadow: 1px 0 16px 0 rgba(0, 0, 0, 0.05);
		z-index: 20000;
		.icon {
			height: 30px;
			text-align: right;
			margin-top: 10px;
			margin-right: 10px;
			cursor: pointer;
		}

		.em {
			width: 30px;
			height: 30px;
			padding: 4px;
			font-size: 16px;
			margin-top: 10px;
			margin-left: 10px;
			box-sizing: border-box;
			cursor: pointer;

			&:hover {
				background-color: #ececec;
			}
		}
	}
</style>
